import React from 'react';
import Layout from 'layout';
import { graphql } from 'gatsby';
import Container from 'elements/Container';
import NewsLetter from 'components/News';
import { useLocation } from '@reach/router';
import { getNewletterList, getNextPrevNewsLetter } from 'utils/links';

export default function NewsLetterPage({ data }) {
  const list = getNewletterList(data.list);
  const post = data.markdownRemark;
  const { date } = post.frontmatter;
  const { next, prev } = getNextPrevNewsLetter(list, date);

  const { search } = useLocation();
  const isMail = search === '?mail';
  return (
    <Layout title={post.frontmatter.title} removeHeaderFooter={isMail}>
      <Container>
        <NewsLetter
          meta={post.frontmatter}
          isMail={isMail}
          next={next}
          prev={prev}
        >
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </NewsLetter>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      tableOfContents
      frontmatter {
        title
        date
        author
        author_title
        banner
        type
        video
      }
    }
    list: allMarkdownRemark(
      filter: { fields: { collection: { eq: "news" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            description
            tags
            author
            banner
            type
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
