import React from 'react';
import dayjs from 'dayjs';
import Pagination from 'elements/Pagination';
import Button from 'elements/Button';
import { CONSOLE_URL } from 'data/urls';
import { Base, Header, Content, Meta } from './style';

export default function NewsLetter({
  children,
  meta,
  isMail = false,
  next,
  prev,
}) {
  const movRegex = new RegExp('.mov$');
  const isMov = meta.video && movRegex.test(meta.video);
  return (
    <Base isMail={isMail}>
      <Header>
        <h3>{meta.type}</h3>
        <h1>{meta.title}</h1>
        {meta.banner ? <img src={meta.banner} alt="" /> : null}
        {meta.video ?
          isMov ? (
            <video width="100%" autoPlay muted loop>
              <source src={meta.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) :
            (
              <iframe
                title={`${meta.title} video`}
                frameBorder={0}
                src={`${meta.video}?loop=1&iv_load_policy=3&rel=0&modestbranding=1`}
              />
            ) : null}
      </Header>
      <div className="grid">
        <Meta>
          <h5>{meta.author}</h5>
          <div className="sub">{meta.author_title}</div>
          <div className="date">{dayjs(meta.date).format('D MMM YYYY')}</div>
          <h2>Try Now</h2>
          <div className="sub">Start building now with data console</div>
          <Button primary as="a" target="_blank" href={CONSOLE_URL}>
            GO TO CONSOLE
          </Button>
        </Meta>
        <Content>{children}</Content>
      </div>

      {!isMail && (next || prev) ? (
        <Pagination next={next} prev={prev} />
      ) : null}
    </Base>
  );
}
