import styled, { css } from 'styled-components';
import { removeLink } from 'styles/mixins';
import { textSM } from 'styles/typography';

export const Base = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 64px;
  max-width: 1100px;
  min-width: 600px;
  ${removeLink};

  ${props =>
    props.isMail
      ? css`
          width: 800px;
          padding: 16px 8px 64px 8px;
        `
      : ''}

  .grid {
    display: flex;
    max-width: 100%;
  }
`;
export const Meta = styled.div`
  min-width: 180px;
  margin-right: 60px;

  h2 {
    margin: 40px 0 10px;
    font-size: 22px;
  }
  h5 {
    color: #000;
    margin: 16px 0 0;
  }
  .date {
    font-size: 12px;
  }
  .sub {
    font-size: 14px;
    padding-bottom: 20px;
    line-height: 20px;
  }
  a {
    font-size: 12px;
    line-height: 28px;
    padding: 4px 12px;
    width: fit-content;
  }
`;

export const Header = styled.div`
  h1 {
    color: rgba(0, 0, 0, 0.9);
    font-size: 44px;
    line-height: 52px;
    margin-top: 0;
  }
  h3 {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
  }
  img,
  iframe {
    display: block;
    margin: 40px 0;
    height: auto;
    border-radius: 2px;
    width: 100%;
  }
  iframe {
    min-height: 600px;
  }
`;

export const Content = styled.div`
  padding-bottom: 80px;

  img {
    max-width: 100%;
  }

  h2 {
    color: rgba(0, 0, 0, 0.9);
    font-size: 28px;
    font-weight: 500;
    margin: 12px 0 6px;
  }

  h3 {
    color: rgba(0, 0, 0, 0.9);
    font-size: 22px;
    font-weight: 500;
    margin: 12px 0 6px;
  }

  h4 {
    color: rgba(0, 0, 0, 0.9);
    font-size: 20px;
    font-weight: 500;
    margin: 12px 0 6px;
  }

  p + p {
    margin-top: 32px;
  }

  p + h2,
  ul + h2 {
    margin-top: 56px;
  }

  ul {
    padding-left: 20px;
    margin: 0;
  }

  h1 + ol {
    list-style: none;
    padding: 0;

    li {
      display: grid;
      grid-template-columns: max-content auto;

      margin: 40px 0;

      h4 {
        font-size: 24px;
        line-height: 32px;
      }
      h4,
      p {
        grid-column: 2 / 3;
        margin: 0 0 8px 0;
      }

      p:first-child {
        grid-column: 1 / 2;
        margin: 0;
        grid-row: 1/4;

        img {
          margin: 0 32px 16px 0;
          width: 136px;
        }
      }
    }
  }

  hr {
    border: 0px;
    border-bottom: 2px solid rgb(242, 242, 242);
    margin: 56px 0;
  }

  .footer-div {
    h3 {
      text-align: center;
      margin-bottom: 32px;
    }
  }
`;

export const List = styled.div`
  max-width: 800px;
  margin: 96px auto;

  .list-item {
    margin-bottom: 40px;

    a:hover {
      h2 {
        color: ${props => props.theme.primary} !important;
      }
    }
    h2 {
      margin: 0 0 8px 0;
    }

    p {
      margin: 0;
    }

    div {
      display: flex;
      margin-top: 12px;
      align-items: center;
    }

    .info {
      ${textSM};
      font-size: 12px;
    }

    .dot {
      width: 4px;
      height: 4px;
      background: rgb(153, 153, 153);
      margin: 8px;
      border-radius: 50%;
    }
  }
`;
